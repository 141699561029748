import React, {useState, useEffect} from 'react';
import {useAuth} from '../context/AuthContext';
import {usePatient} from '../context/PatientContext';
import {readICDCodesFromLocalFile} from '../utils/readExcel';
import {
    Box,
    Paper,
    Typography,
    TextField,
    Button,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    Chip,
    Autocomplete,
    Menu,
    MenuItem,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ReactMarkdown from 'react-markdown';
import './Notes.css';

export const fetchNotes = async (userId, patientId) => {
    try {
        const response = await fetch(`/api/get_notes/${userId}/${patientId}`);
        const data = await response.json();
        return data.all_notes || [];
    } catch (error) {
        console.error('Error fetching notes:', error);
        return [];
    }
};

const Instructions = () => (
    <Paper elevation={3} sx={{p: 3, mb: 3, width: '100%'}}>
        <Typography variant="body1">
            Create, manage, and store clinical notes. You can even generate a structured SOAP or DAP note from your
            clinical note.
        </Typography>
    </Paper>
);

const Notes = () => {
    const {user} = useAuth();
    const {selectedPatient} = usePatient();
    const [allNotes, setAllNotes] = useState([]);
    const [clinicalText, setClinicalText] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [primaryDiagnosis, setPrimaryDiagnosis] = useState('');
    const [secondaryDiagnosis, setSecondaryDiagnosis] = useState('');
    const [tertiaryDiagnosis, setTertiaryDiagnosis] = useState('');
    const [diagnosesList, setDiagnosesList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [conversionType, setConversionType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [convertedNote, setConvertedNote] = useState('');
    const [editableConvertedNote, setEditableConvertedNote] = useState('');
    const [showConvertedNote, setShowConvertedNote] = useState(false);

    useEffect(() => {
        if (user && selectedPatient) {
            const loadNotes = async () => {
                const fetchedNotes = await fetchNotes(user.uid, selectedPatient.id);
                setAllNotes(fetchedNotes);
            };
            loadNotes();
        } else {
            setAllNotes([]); // Clear notes when no patient is selected
        }
    }, [user, selectedPatient]);

    useEffect(() => {
        const fetchDiagnoses = async () => {
            const data = await readICDCodesFromLocalFile();
            setDiagnosesList(data || []);
        };

        fetchDiagnoses();
    }, []);

    const resetForm = () => {
        setClinicalText('');
        setStartTime('');
        setEndTime('');
        setPrimaryDiagnosis('');
        setSecondaryDiagnosis('');
        setTertiaryDiagnosis('');
    };

    const handleConvertClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleConversionTypeSelect = (type) => {
        setConversionType(type);
        setAnchorEl(null);
        handleConvertNote(type);
    };

    const handleSaveNote = async (noteType) => {
        if (!clinicalText && noteType === 'clinical') return;
        if (!editableConvertedNote && (noteType === 'soap' || noteType === 'dap')) return;

        try {
            const noteData = {
                note_type: noteType,
                note_content: noteType === 'clinical' ? clinicalText : editableConvertedNote,
                start_time: startTime || null,
                end_time: endTime || null,
                primary_diagnosis: primaryDiagnosis || null,
                secondary_diagnosis: secondaryDiagnosis || null,
                tertiary_diagnosis: tertiaryDiagnosis || null,
            };

            const response = await fetch(`/api/save_note/${user.uid}/${selectedPatient.id}`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(noteData),
            });
            const result = await response.json();
            if (result.success) {
                const newNote = {
                    id: result.id,
                    ...noteData,
                    timestamp: new Date().toISOString()
                };
                setAllNotes([newNote, ...allNotes]);
                if (noteType === 'clinical') {
                    resetForm();
                } else {
                    setShowConvertedNote(false);
                    setConvertedNote('');
                    setEditableConvertedNote('');
                }
            } else {
                console.error(result.error);
            }
        } catch (error) {
            console.error('Error saving note:', error);
        }
    };

    const handleConvertNote = async (type) => {
        setLoading(true);
        setConvertedNote('');
        setEditableConvertedNote('');
        setShowConvertedNote(false);
        try {
            const response = await fetch('/api/generate_insights_notes', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    userId: user.uid,
                    patientId: selectedPatient.id,
                    note: clinicalText,
                    start_time: startTime || null,
                    end_time: endTime || null,
                    primaryDiagnosis: primaryDiagnosis || null,
                    secondaryDiagnosis: secondaryDiagnosis || null,
                    tertiaryDiagnosis: tertiaryDiagnosis || null,
                    conversionType: type,
                }),
            });
            const result = await response.json();

            console.log('Conversion result:', result);


            if (result.error) {
                console.error(result.error);
            } else {
                setConvertedNote(result.converted_note);
                setShowConvertedNote(true);
                setEditableConvertedNote(result.converted_note);
                setShowConvertedNote(true);
                console.log(result.converted_note);

            }
        } catch (error) {
            console.error('Error converting note:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{
            width: '95%',
            maxWidth: '1500px',
            margin: '0 auto',
            padding: 2,
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Instructions/>
            <Paper elevation={3} sx={{
                p: 3,
                mb: 3,
                width: '100%',
                minHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h5" gutterBottom sx={{mb: 3}}>Create Note</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Session Start Time"
                            type="datetime-local"
                            value={startTime}
                            onChange={(e) => setStartTime(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label="Session End Time"
                            type="datetime-local"
                            value={endTime}
                            onChange={(e) => setEndTime(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={diagnosesList}
                            getOptionLabel={(option) => option.combined}
                            renderInput={(params) => <TextField {...params} label="Primary Diagnosis" fullWidth/>}
                            onChange={(event, newValue) => {
                                setPrimaryDiagnosis(newValue ? newValue.combined : '');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={diagnosesList}
                            getOptionLabel={(option) => option.combined}
                            renderInput={(params) => <TextField {...params} label="Secondary Diagnosis" fullWidth/>}
                            onChange={(event, newValue) => {
                                setSecondaryDiagnosis(newValue ? newValue.combined : '');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={diagnosesList}
                            getOptionLabel={(option) => option.combined}
                            renderInput={(params) => <TextField {...params} label="Tertiary Diagnosis" fullWidth/>}
                            onChange={(event, newValue) => {
                                setTertiaryDiagnosis(newValue ? newValue.combined : '');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            rows={6}
                            variant="outlined"
                            label="Enter Note"
                            value={clinicalText}
                            onChange={(e) => setClinicalText(e.target.value)}
                            required
                        />
                    </Grid>
                </Grid>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-start'}}>
                    <Button
                        onClick={() => handleSaveNote('clinical')}
                        variant="contained"
                        color="primary"
                        disabled={!clinicalText || clinicalText.trim() === ''}
                        sx={{ mr: 3 }}
                    >
                        Save Note
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleConvertClick}
                        disabled={!clinicalText || clinicalText.trim() === ''}
                        endIcon={<ArrowDropDownIcon/>}
                    >
                        Convert Note
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleConversionTypeSelect('SOAP')}>SOAP Note</MenuItem>
                        <MenuItem onClick={() => handleConversionTypeSelect('DAP')}>DAP Note</MenuItem>
                    </Menu>
                </Box>
            </Paper>

            {loading && (
                <Box sx={{display: 'flex', justifyContent: 'center', mt: 2, width: '100%'}}>
                    <CircularProgress/>
                </Box>
            )}

            {showConvertedNote && (
                <Paper elevation={3} sx={{
                    p: 3,
                    mb: 3,
                    width: '100%',
                    minHeight: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <Typography variant="h6" gutterBottom>{conversionType} Note</Typography>
                    <TextField
                        fullWidth
                        multiline
                        rows={10}
                        variant="outlined"
                        value={editableConvertedNote}
                        onChange={(e) => setEditableConvertedNote(e.target.value)}
                    />
                    <Button
                        onClick={() => handleSaveNote(conversionType.toLowerCase())}
                        variant="contained"
                        color="primary"
                        sx={{mt: 2, alignSelf: 'flex-end'}}
                        disabled={!editableConvertedNote || !editableConvertedNote.trim()}
                    >
                        Save {conversionType} Note
                    </Button>
                </Paper>
            )}

            <Paper elevation={3} sx={{
                p: 3,
                width: '100%',
                minHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h5" gutterBottom sx={{mb: 3}}>Notes History</Typography>
                {allNotes.length > 0 ? (
                    allNotes.map((note, index) => (
                        <Accordion key={index} sx={{mb: 2, width: '100%'}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon/>}
                                sx={{
                                    '& .MuiAccordionSummary-content': {
                                        display: 'flex',
                                        alignItems: 'center',
                                    },
                                }}
                            >
                                <Chip
                                    label={new Date(note.timestamp).toLocaleString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                    size="small"
                                    color="primary"
                                    variant="outlined"
                                    sx={{mr: 2}}
                                />
                            </AccordionSummary>
                            <AccordionDetails>
                                <ReactMarkdown>{note.SOAP_note || note.text || note.note_content}</ReactMarkdown>
                                {note.start_time && (
                                    <Typography variant="body2">Session Start
                                        Time: {new Date(note.start_time).toLocaleString()}</Typography>
                                )}
                                {note.end_time && (
                                    <Typography variant="body2">Session End
                                        Time: {new Date(note.end_time).toLocaleString()}</Typography>
                                )}
                                {note.primary_diagnosis && (
                                    <Chip label={`Primary: ${note.primary_diagnosis}`} sx={{mr: 1, mt: 1}}/>
                                )}
                                {note.secondary_diagnosis && (
                                    <Chip label={`Secondary: ${note.secondary_diagnosis}`} sx={{mr: 1, mt: 1}}/>
                                )}
                                {note.tertiary_diagnosis && (
                                    <Chip label={`Tertiary: ${note.tertiary_diagnosis}`} sx={{mr: 1, mt: 1}}/>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))
                ) : (
                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: 'center',
                            color: 'text.secondary',
                            mt: 4
                        }}
                    >
                        No notes have been created yet. Use the form above to create your first note.
                    </Typography>
                )}
            </Paper>
        </Box>
    );
};

export default Notes;