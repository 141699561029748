import React, {useState, useEffect} from 'react';
import {useAuth} from '../context/AuthContext';
import {usePatient} from '../context/PatientContext';
import QuestionnaireBuilder from '../components/QuestionnaireBuilder';
import ViewResponses from '../components/ViewResponses';
import {
    Box,
    Typography,
    Paper,
    Button,
    Modal,
    useTheme,
    useMediaQuery,
    Tabs,
    Tab
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';

const DEFAULT_QUESTIONS = [
    {
        text: "Please describe the self-talk that happens when you make a significant mistake at work or in your personal life. What goes through your mind, and how do you handle such a situation?",
        editable: false
    },
    {
        text: "Before making an important decision in life, what do you do? Please describe your process for making the decision.",
        editable: false
    },
    {
        text: "Please describe your existing connections with a few key people in your life (family, friendships, intimate) and how you feel about these relationships.",
        editable: false
    },
    {
        text: "In the area(s) of life you find most challenging, please describe your possible responses when experiencing intense insecurities, moodiness, anxiety, or other negative thoughts/emotions.",
        editable: false
    },
    {
        text: "How do you deal with conflicts in your important relationships (family members, close friends, colleagues, or significant other)?",
        editable: false
    },
    {text: "Please describe the issues that brought you to seek therapy.", editable: true},
    {text: "Please describe your relationships with your parents (or caretakers) growing up.", editable: true},
    {text: "What are your hopes and dreams for your future?", editable: true},
    {
        text: "Describe one childhood memory (before age 8) in detail, including the emotions you felt at that moment. Be as specific as possible.",
        editable: true
    },
    {
        text: "Please share any other experiences or life events that you believe have shaped you. Be specific and explain why these experiences and events are so formative.",
        editable: true
    }
];

const Instructions = () => (
    <Paper elevation={3} sx={{p: 3, mb: 3, width: '100%'}}>
            <Typography variant="body1">
                Build intake and custom questionnaires to send to clients. Psyntel's standard intake questionnaire
                offers 5 pre-filled questions along with 5 editable ones. Or, opt for the fully customizable
                questionnaire.
            </Typography>
    </Paper>
);

const Questionnaires = () => {
    const [intakeQuestions, setIntakeQuestions] = useState(DEFAULT_QUESTIONS);
    const [customQuestions, setCustomQuestions] = useState([]);
    const {user} = useAuth();
    const {selectedPatient} = usePatient();
    const [sessionId, setSessionId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [tabValue, setTabValue] = useState(0);
    const [editingIndex, setEditingIndex] = useState(null);

    useEffect(() => {
        if (selectedPatient) {
            fetchLatestQuestions();
        }
    }, [selectedPatient]);

    const fetchLatestQuestions = async () => {
        if (!user || !selectedPatient) return;

        try {
            const response = await fetch(`/api/prep_questionnaire/${user.uid}/${selectedPatient.id}`);
            if (response.ok) {
                const data = await response.json();
                setIntakeQuestions(data.questions);
            } else {
                console.error('Failed to fetch latest questions');
            }
        } catch (error) {
            console.error('Error fetching latest questions:', error);
        }
    };

    const handleSendQuestionnaire = async () => {
        if (!selectedPatient) return;

        try {
            const prepResponse = await fetch(`/api/prep_questionnaire/${user.uid}/${selectedPatient.id}`);
            const prepData = await prepResponse.json();

            if (prepResponse.ok) {
                const formData = new FormData();
                formData.append('patient_id', selectedPatient.id);
                formData.append('token', prepData.token);
                formData.append('questions', JSON.stringify(tabValue === 0 ? intakeQuestions : customQuestions));
                formData.append('questionnaire_type', tabValue === 0 ? 'intake' : 'custom');

                const sendResponse = await fetch('/api/send_questionnaire', {
                    method: 'POST',
                    body: formData,
                });

                const sendData = await sendResponse.json();

                if (sendResponse.ok) {
                    setSessionId(sendData.session_id);
                    setIsModalOpen(true);
                } else {
                    console.error(sendData.error || 'Failed to send questionnaire.');
                }
            } else {
                console.error('Failed to prepare questionnaire.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleQuestionsUpdate = (updatedQuestions) => {
        if (tabValue === 0) {
            setIntakeQuestions(updatedQuestions);
        } else {
            setCustomQuestions(updatedQuestions);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const renderInstructions = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                p: 3,
            }}
        >
            <Typography variant="h6" gutterBottom>
                Create Your Custom Questionnaire
            </Typography>
            <Typography variant="body1" paragraph>
                Start by clicking the "Add Question" button to create your personalized set of questions.
            </Typography>
            <Button
                variant="outlined"
                startIcon={<AddIcon/>}
                onClick={handleAddQuestion}
                sx={{mt: 2}}
            >
                Add Question
            </Button>
        </Box>
    );

    const handleAddQuestion = () => {
        const newQuestion = {text: '', editable: true};
        if (tabValue === 0) {
            setIntakeQuestions([...intakeQuestions, newQuestion]);
        } else {
            setCustomQuestions([...customQuestions, newQuestion]);
        }
        setEditingIndex(tabValue === 0 ? intakeQuestions.length : customQuestions.length);
    };

    const handleDiscardQuestion = (index) => {
        if (tabValue === 0) {
            setIntakeQuestions(intakeQuestions.filter((_, i) => i !== index));
        } else {
            setCustomQuestions(customQuestions.filter((_, i) => i !== index));
        }
        setEditingIndex(null);
    };

    const handleSaveQuestion = (index, text) => {
        if (tabValue === 0) {
            const updatedQuestions = [...intakeQuestions];
            updatedQuestions[index] = {text, editable: true};
            setIntakeQuestions(updatedQuestions);
        } else {
            const updatedQuestions = [...customQuestions];
            updatedQuestions[index] = {text, editable: true};
            setCustomQuestions(updatedQuestions);
        }
        setEditingIndex(null);
    };


    return (
        <Box sx={{
            width: '95%',
            maxWidth: '1500px',
            margin: '0 auto',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Instructions/>
            <Paper elevation={3} sx={{
                p: 3,
                mb: 3,
                width: '100%',
                minHeight: '700px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h5" sx={{mb: 2}}>
                    Questionnaire Builder
                </Typography>
                <Tabs value={tabValue} onChange={handleTabChange} sx={{mb: 2}}>
                    <Tab label="Intake Questionnaire"/>
                    <Tab label="Custom Questionnaire"/>
                </Tabs>
                <Box sx={{flexGrow: 1, display: 'flex', flexDirection: 'column'}}>
                    {tabValue === 1 && customQuestions.length === 0 ? (
                        renderInstructions()
                    ) : (
                        <QuestionnaireBuilder
                            questions={tabValue === 0 ? intakeQuestions : customQuestions}
                            onQuestionsUpdate={tabValue === 0 ? setIntakeQuestions : setCustomQuestions}
                            selectedPatientId={selectedPatient ? selectedPatient.id : null}
                            isIntake={tabValue === 0}
                            onAddQuestion={handleAddQuestion}
                            onDiscardQuestion={handleDiscardQuestion}
                            onSaveQuestion={handleSaveQuestion}
                            editingIndex={editingIndex}
                            setEditingIndex={setEditingIndex}
                        />
                    )}
                </Box>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SendIcon/>}
                        onClick={handleSendQuestionnaire}
                        disabled={!selectedPatient || (tabValue === 0 ? intakeQuestions.length === 0 : customQuestions.length === 0)}
                    >
                        Send Questionnaire
                    </Button>
                </Box>
            </Paper>

            <Paper elevation={3} sx={{
                p: 3,
                width: '100%',
                minHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h5" sx={{mb: 2}}>
                    View Responses
                </Typography>
                <ViewResponses selectedPatientId={selectedPatient ? selectedPatient.id : null}/>
            </Paper>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Success
                    </Typography>
                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        Questionnaire sent successfully!
                    </Typography>
                    <Button onClick={handleCloseModal} sx={{mt: 2}}>Close</Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default Questionnaires;