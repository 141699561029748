import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Login from './components/Login';
import Registration from './components/Registration';
import Dashboard from './components/Dashboard';
import Notes from './pages/Notes';
import Questionnaires from './pages/Questionnaires';
import Insights from './pages/Insights';
import SettingsMenu from './components/SettingsMenu';
import FillQuestionnaire from './components/FillQuestionnaire';
import NotFound from './components/NotFound';
import {useAuth} from './context/AuthContext';
import {PatientProvider} from './context/PatientContext';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

library.add(faTrash);

const PrivateRoute = ({children}) => {
    const {user} = useAuth();
    return user ? children : <Navigate to="/login"/>;
};

function App() {
    return (
        <Router>
            <PatientProvider>
                <Routes>
                    <Route path="/" element={<Navigate to="/login"/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/register" element={<Registration/>}/>
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute>
                                <Dashboard/>
                            </PrivateRoute>
                        }
                    >
                        <Route index element={<Navigate to="notes"/>}/>
                        <Route path="notes" element={<Notes/>}/>
                        <Route path="questionnaires" element={<Questionnaires/>}/>
                        <Route path="insights" element={<Insights/>}/>
                        <Route path="settings" element={<SettingsMenu/>}/>
                    </Route>
                    <Route
                        path="/fill-questionnaire/:token/:patient_id"
                        element={<FillQuestionnaire/>}
                    />
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </PatientProvider>
        </Router>
    );
}

export default App;