import React, {useState, useEffect} from 'react';
import {Outlet, useNavigate, useLocation} from 'react-router-dom';
import {useAuth} from '../context/AuthContext';
import {usePatient} from '../context/PatientContext';
import Sidebar from './Sidebar';
import TopBanner from './TopBanner';
import ChatSidebar from './ChatSidebar';
import AddPatientModal from './AddPatientModal';
import styles from './Dashboard.module.css';
import {db} from '../config/firebaseConfig';
import {collection, query, getDocs} from 'firebase/firestore';
import {Box, Typography, Button} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AppFooter from './AppFooter';


const Dashboard = () => {
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(true);
    const {user, logout} = useAuth();
    const {selectedPatient, setSelectedPatient} = usePatient();
    const [sidebarHidden, setSidebarHidden] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [chatSidebarWidth, setChatSidebarWidth] = useState(350);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();


    const [activeView, setActiveView] = useState('notes');

    // Debug: Log user object when it changes
    useEffect(() => {
    }, [user]);

    useEffect(() => {
        const path = location.pathname.split('/').pop();
        setActiveView(path || 'notes');
    }, [location]);

    useEffect(() => {
        const fetchPatients = async () => {
            if (!user) {
                setLoading(false);
                setError('User not authenticated');
                return;
            }

            try {
                const q = query(collection(db, 'Psyntel', user.uid, 'patients'));
                const querySnapshot = await getDocs(q);
                const patientsData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setPatients(patientsData);
            } catch (error) {
                console.error('Error fetching patients:', error);
                setError('Failed to fetch patients');
            } finally {
                setLoading(false);
            }
        };

        fetchPatients();
    }, [user]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed:', error);
            setError('Failed to logout. Please try again.');
        }
    };

    if (loading) return <div className={styles.dashboardLoading}>Loading...</div>;
    if (error) return <div className={styles.dashboardError}>Error: {error}</div>;
    if (!user) return <div className={styles.dashboardError}>Error: User data is missing</div>;

    const toggleSidebar = () => setSidebarHidden(!sidebarHidden);
    const handlePatientSelection = (patient) => setSelectedPatient(patient);
    const handleAddPatient = () => setModalVisible(true);
    const handleCloseModal = () => setModalVisible(false);
    const handlePatientAdded = (newPatient) => {
        setPatients([...patients, newPatient]);
        setModalVisible(false);
    };

    const handleSetActiveView = (view) => {
        setActiveView(view);
        navigate(view);
    };

    const handleChatSidebarResize = (newWidth) => {
        setChatSidebarWidth(newWidth);
    };

    return (
        <div className={styles.dashboardContainer}>
            <TopBanner
                activeView={activeView}
                setActiveView={handleSetActiveView}
                userName={user.displayName}
                toggleSidebar={toggleSidebar}
                onLogout={handleLogout}
            />
            <div
                className={styles.dashboardContent}
                style={{
                    "--chat-sidebar-width": `${chatSidebarWidth}px`,
                    "--sidebar-width": sidebarHidden ? "0px" : "250px"
                }}
            >
                <div
                    className={`${styles.dashboardSidebarContainer} ${sidebarHidden ? styles.dashboardSidebarHidden : ''}`}>
                    <Sidebar
                        patients={patients}
                        setPatients={setPatients}
                        onSelectPatient={handlePatientSelection}
                        onAddPatient={handleAddPatient}
                    />
                </div>
                <div className={styles.dashboardMainContentWrapper}>
                    <div className={styles.dashboardMainContent}>
                        {activeView === 'settings' ? (
                            <Outlet/>
                        ) : patients.length === 0 ? (
                            <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                height="100%"
                            >
                                <Typography variant="h5" gutterBottom>
                                    Welcome to Psyntel!
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    To get started, add your first client.
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon/>}
                                    onClick={handleAddPatient}
                                    sx={{mt: 2}}
                                >
                                    Add Client
                                </Button>
                            </Box>
                        ) : selectedPatient ? (
                            <Outlet/>
                        ) : (
                            <div className={styles.dashboardNoPatientSelected}>Please select a client from the
                                sidebar.</div>
                        )}
                        <AppFooter/>
                    </div>
                </div>
                {activeView !== 'settings' && (
                    <ChatSidebar
                        onResize={handleChatSidebarResize}
                        initialWidth={chatSidebarWidth}
                    />
                )}
            </div>
            <AddPatientModal
                show={modalVisible}
                handleClose={handleCloseModal}
                onPatientAdded={handlePatientAdded}
            />
        </div>
    );
};

export default Dashboard;