import React, {useState, useEffect} from 'react';
import {useAuth} from '../context/AuthContext';
import {usePatient} from '../context/PatientContext';
import {fetchNotes} from './Notes';
import ReactMarkdown from 'react-markdown';
import {
    Box, Paper, Typography, Button, Checkbox,
    FormControlLabel, Select, MenuItem, CircularProgress,
    Chip, useTheme, useMediaQuery, Accordion, AccordionSummary, AccordionDetails,
    Grid, IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HistoryIcon from '@mui/icons-material/History';
import EventNoteIcon from '@mui/icons-material/EventNote';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import TypewriterEffect from '../components/TypewriterEffect';


const SelectableQuestionnaireResponses = ({
                                              selectedPatientId,
                                              onSelectionChange,
                                              selectedQuestionnaires,
                                              responses,
                                              setResponses
                                          }) => {
    const {user} = useAuth();

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await fetch(`/api/view_responses/${user.uid}/${selectedPatientId}`);
                const data = await response.json();
                setResponses(data.responses || []);
            } catch (error) {
                console.error('Error fetching questionnaire responses:', error);
            }
        };

        if (selectedPatientId) {
            fetchResponses();
        }
    }, [user.uid, selectedPatientId, setResponses]);

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return 'No timestamp';
        const date = new Date(timestamp);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    return (
        <Box>
            <Typography variant="h6" gutterBottom sx={{display: 'flex', alignItems: 'center', color: 'primary.main'}}>
                <QuestionAnswerIcon sx={{mr: 1, fontSize: 24}}/> Questionnaire Responses
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {responses.map((response) => (
                    <FormControlLabel
                        key={response.session_id}
                        control={
                            <Checkbox
                                checked={selectedQuestionnaires.includes(response.session_id)}
                                onChange={(e) => onSelectionChange(response.session_id, e.target.checked)}
                            />
                        }
                        label={formatTimestamp(response.timestamp)}
                    />
                ))}
            </Box>
        </Box>
    );
};

const Instructions = () => (
    <Paper elevation={3} sx={{p: 3, mb: 3, width: '100%'}}>
        <Typography variant="body1">
            Generate psychological profiles and progress reports based on client notes and questionnaire responses.
            View and manage your insight history for each client.
        </Typography>
    </Paper>
);

const Insights = () => {
    const {user} = useAuth();
    const {selectedPatient} = usePatient();
    const [notes, setNotes] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([]);
    const [responses, setResponses] = useState([]);
    const [generatedInsight, setGeneratedInsight] = useState('');
    const [insightHistory, setInsightHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reportType, setReportType] = useState('psychological_profile');
    const [expandedInsight, setExpandedInsight] = useState(null);
    const [unsavedInsight, setUnsavedInsight] = useState(null);
    const [startTypewriter, setStartTypewriter] = useState(false);


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if (selectedPatient) {
            fetchPatientNotes();
            fetchInsightHistory();
            // Clear unsaved insights when switching patients
            setUnsavedInsight(null);
            setStartTypewriter(false);
        }
    }, [selectedPatient]);

    useEffect(() => {
        if (notes.length > 0) {
            setSelectedData(notes.map(note => ({type: 'note', id: note.id})));
        }
    }, [notes]);

    useEffect(() => {
        if (responses.length > 0) {
            setSelectedQuestionnaires(responses.map(response => response.session_id));
        }
    }, [responses]);

    const fetchPatientNotes = async () => {
        try {
            const fetchedNotes = await fetchNotes(user.uid, selectedPatient.id);
            setNotes(fetchedNotes);
        } catch (error) {
            console.error('Error fetching notes:', error);
        }
    };

    const fetchInsightHistory = async () => {
        try {
            const response = await fetch(`/api/insight_history/${user.uid}/${selectedPatient.id}`);
            const data = await response.json();
            setInsightHistory(data);
        } catch (error) {
            console.error('Error fetching insight history:', error);
        }
    };

    const handleDataSelection = (dataType, id) => {
        setSelectedData(prev => {
            const existingItem = prev.find(item => item.id === id && item.type === dataType);
            if (existingItem) {
                return prev.filter(item => item.id !== id || item.type !== dataType);
            } else {
                return [...prev, {type: dataType, id}];
            }
        });
    };

    const handleQuestionnaireSelection = (id, isSelected) => {
        setSelectedQuestionnaires(prev => {
            if (isSelected) {
                return [...prev, id];
            } else {
                return prev.filter(qId => qId !== id);
            }
        });
    };

    const generateInsight = async () => {
        setLoading(true);
        try {
            const selectedDataForInsight = [
                ...selectedData,
                ...selectedQuestionnaires.map(id => ({type: 'questionnaire', id}))
            ];

            const response = await fetch('/api/generate_insights', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.uid,
                    patientId: selectedPatient.id,
                    selectedData: selectedDataForInsight,
                    reportType: reportType,
                }),
            });
            const data = await response.json();
            setUnsavedInsight(data.insight);
            setStartTypewriter(true);  // Start the typewriter effect
        } catch (error) {
            console.error('Error generating insight:', error);
        }
        setLoading(false);
    };

    const handleSaveInsight = async () => {
        try {
            const response = await fetch('/api/save_insight', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: user.uid,
                    patientId: selectedPatient.id,
                    content: unsavedInsight,
                    reportType: reportType,
                }),
            });
            const data = await response.json();
            setUnsavedInsight(null);
            fetchInsightHistory();
        } catch (error) {
            console.error('Error saving insight:', error);
        }
    };

    const handleDiscardInsight = () => {
        setUnsavedInsight(null);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedInsight(isExpanded ? panel : null);
    };

    const handleDownload = async (insightId, reportType) => {
        try {
            const response = await fetch(`/api/export_insights/${user.uid}/${selectedPatient.id}/${insightId}/${reportType}`);

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${reportType}_${insightId}.pdf`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                console.error('Error downloading report:', response.statusText);
            }
        } catch (error) {
            console.error('Error downloading report:', error);
        }
    };
    return (
        <Box sx={{
            width: '95%',
            maxWidth: '1500px',
            margin: '0 auto',
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
            <Instructions/>
            <Paper elevation={3} sx={{
                p: 3,
                mb: 3,
                width: '100%',
                minHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h5" gutterBottom
                            sx={{display: 'flex', alignItems: 'center', mb: 3, color: theme.palette.primary.main}}>
                    Generate New Insight
                </Typography>

                <Box sx={{mb: 3}}>
                    <Typography variant="subtitle1" gutterBottom>1. Select Report Type</Typography>
                    <Select
                        value={reportType}
                        onChange={(e) => setReportType(e.target.value)}
                        fullWidth
                        sx={{mb: 2}}
                    >
                        <MenuItem value="psychological_profile">Psychological Profile</MenuItem>
                        <MenuItem value="progress_report">Progress Report</MenuItem>
                    </Select>
                </Box>

                <Box sx={{mb: 3, flex: '1 1 auto', overflowY: 'auto'}}>
                    <Typography variant="subtitle1" gutterBottom>2. Select Data for Analysis</Typography>
                    <Box sx={{display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2}}>
                        <Paper elevation={2} sx={{
                            flexBasis: '50%',
                            p: 2,
                            backgroundColor: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}>
                            <Typography variant="h6" gutterBottom
                                        sx={{display: 'flex', alignItems: 'center', color: 'primary.main'}}>
                                <EventNoteIcon sx={{mr: 1, fontSize: 24}}/> Notes
                            </Typography>
                            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                                {notes.length > 0 ? (
                                    notes.map(note => (
                                        <FormControlLabel
                                            key={note.id}
                                            control={
                                                <Checkbox
                                                    checked={selectedData.some(item => item.id === note.id && item.type === 'note')}
                                                    onChange={() => handleDataSelection('note', note.id)}
                                                />
                                            }
                                            label={`${new Date(note.timestamp).toLocaleString()} - ${note.text ? note.text.substring(0, 50) + '...' : 'No text available'}`}
                                        />
                                    ))
                                ) : (
                                    <Typography variant="body2" color="text.secondary">No notes available for this
                                        client.</Typography>
                                )}
                            </Box>
                        </Paper>
                        <Paper elevation={2} sx={{
                            flexBasis: '50%',
                            p: 2,
                            backgroundColor: theme.palette.background.paper,
                            border: `1px solid ${theme.palette.divider}`,
                            '&:hover': {
                                boxShadow: theme.shadows[4],
                            },
                        }}>
                            <SelectableQuestionnaireResponses
                                selectedPatientId={selectedPatient?.id}
                                onSelectionChange={handleQuestionnaireSelection}
                                selectedQuestionnaires={selectedQuestionnaires}
                                responses={responses}
                                setResponses={setResponses}
                            />
                            {responses.length === 0 && (
                                <Typography variant="body2" color="text.secondary">No questionnaire responses available
                                    for this client.</Typography>
                            )}
                        </Paper>
                    </Box>
                </Box>

                <Box sx={{display: 'flex', justifyContent: 'center', mt: 2}}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={generateInsight}
                        disabled={loading || (selectedData.length === 0 && selectedQuestionnaires.length === 0)}
                        sx={{
                            minWidth: '200px',
                            height: '50px',
                            borderRadius: '25px',
                            textTransform: 'none',
                            fontSize: '16px'
                        }}
                    >
                        {loading ? <CircularProgress
                            size={24}/> : `Generate ${reportType === 'psychological_profile' ? 'Psychological Profile' : 'Progress Report'}`}
                    </Button>
                </Box>
            </Paper>

            {generatedInsight && (
                <Paper elevation={3} sx={{
                    p: 3,
                    mb: 3,
                    width: '100%',
                    minHeight: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>

                    <Typography variant="h5" gutterBottom sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                        Generated Insight
                    </Typography>
                    <ReactMarkdown>{generatedInsight}</ReactMarkdown>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                        <Button
                            startIcon={<SaveIcon/>}
                            variant="contained"
                            color="primary"
                            onClick={handleSaveInsight}
                            sx={{mr: 1}}
                        >
                            Save
                        </Button>
                        <Button
                            startIcon={<DeleteIcon/>}
                            variant="outlined"
                            color="secondary"
                            onClick={handleDiscardInsight}
                        >
                            Discard
                        </Button>
                    </Box>
                </Paper>
            )}

            {unsavedInsight && (
                <Paper elevation={3} sx={{
                    p: 3,
                    mb: 3,
                    width: '100%',
                    minHeight: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative'
                }}>

                    <Typography variant="h5" gutterBottom sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                        Generated Insight
                    </Typography>
                    <Box sx={{position: 'absolute', top: 16, right: 16}}>
                        <Button
                            startIcon={<SaveIcon/>}
                            variant="contained"
                            color="primary"
                            onClick={handleSaveInsight}
                            sx={{mr: 1}}
                        >
                            Save
                        </Button>
                        <Button
                            startIcon={<DeleteIcon/>}
                            variant="outlined"
                            color="secondary"
                            onClick={handleDiscardInsight}
                        >
                            Discard
                        </Button>
                    </Box>
                    {startTypewriter ? (
                        <TypewriterEffect text={unsavedInsight} speed={10}/>
                    ) : (
                        <ReactMarkdown>{unsavedInsight}</ReactMarkdown>
                    )}
                </Paper>
            )}

            <Paper elevation={3} sx={{
                p: 3,
                width: '100%',
                minHeight: '300px',
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant="h5" gutterBottom sx={{display: 'flex', alignItems: 'center', mb: 3}}>
                    <HistoryIcon sx={{mr: 1}}/> Insight History
                </Typography>
                {insightHistory.length === 0 ? (
                    <Typography color="text.secondary">No insights have been generated yet. Use the form above to
                        generate your first insight.</Typography>
                ) : (
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>Psychological Profiles</Typography>
                            {insightHistory
                                .filter(insight => insight.report_type === 'psychological_profile')
                                .map((insight) => (
                                    <Accordion
                                        key={insight.id}
                                        sx={{mb: 2}}
                                        expanded={expandedInsight === insight.id}
                                        onChange={handleAccordionChange(insight.id)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            sx={{
                                                '& .MuiAccordionSummary-expandIconWrapper': {
                                                    transform: 'rotate(0deg)',
                                                },
                                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                                    transform: 'rotate(180deg)',
                                                },
                                            }}
                                        >
                                            <Chip
                                                label={new Date(insight.timestamp).toLocaleString()}
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                                sx={{mr: 2}}
                                            />
                                            {expandedInsight === insight.id && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDownload(insight.id, 'psychological_profile');
                                                    }}
                                                >
                                                    <DownloadIcon/>
                                                </IconButton>
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ReactMarkdown>{insight.content || ''}</ReactMarkdown>
                                            {insight.data_used && (
                                                <Typography variant="body2" sx={{mt: 2}}>
                                                    <strong>Data used:</strong> {insight.data_used.join(', ')}
                                                </Typography>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" gutterBottom>Progress Reports</Typography>
                            {insightHistory
                                .filter(insight => insight.report_type === 'progress_report')
                                .map((insight) => (
                                    <Accordion
                                        key={insight.id}
                                        sx={{mb: 2}}
                                        expanded={expandedInsight === insight.id}
                                        onChange={handleAccordionChange(insight.id)}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon/>}
                                            sx={{
                                                '& .MuiAccordionSummary-expandIconWrapper': {
                                                    transform: 'rotate(0deg)',
                                                },
                                                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                                    transform: 'rotate(180deg)',
                                                },
                                            }}
                                        >
                                            <Chip
                                                label={new Date(insight.timestamp).toLocaleString()}
                                                size="small"
                                                color="primary"
                                                variant="outlined"
                                                sx={{mr: 2}}
                                            />
                                            {expandedInsight === insight.id && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDownload(insight.id, 'progress_report');
                                                    }}
                                                >
                                                    <DownloadIcon/>
                                                </IconButton>
                                            )}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <ReactMarkdown>{insight.content || ''}</ReactMarkdown>
                                            {insight.data_used && (
                                                <Typography variant="body2" sx={{mt: 2}}>
                                                    <strong>Data used:</strong> {insight.data_used.join(', ')}
                                                </Typography>
                                            )}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                        </Grid>
                    </Grid>
                )}
            </Paper>
        </Box>
    );
};

export default Insights;