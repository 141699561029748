import React, { useState } from 'react';
import {
    Button,
    TextField,
    Box,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Typography,
    Paper
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const QuestionnaireBuilder = ({
    questions,
    onQuestionsUpdate,
    selectedPatientId,
    isIntake,
    onAddQuestion,
    onDiscardQuestion,
    onSaveQuestion,
    editingIndex,
    setEditingIndex
}) => {
    const [editingText, setEditingText] = useState('');

    const handleEdit = (index) => {
        setEditingIndex(index);
        setEditingText(questions[index].text);
    };

    const handleEditSubmit = (index) => {
        onSaveQuestion(index, editingText);
        setEditingText('');
    };

    const handleEditCancel = (index) => {
        if (questions[index].text === '') {
            onDiscardQuestion(index);
        } else {
            setEditingIndex(null);
        }
        setEditingText('');
    };

    return (
        <Box>
            <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={onAddQuestion}
                    disabled={questions.length >= 10}
                    sx={{
                        color: 'primary.main',
                        borderColor: 'primary.main',
                        backgroundColor: 'white',
                        '&:hover': {
                            backgroundColor: 'primary.light',
                        },
                        mr: 2
                    }}
                >
                    Add Question
                </Button>
                <Typography variant="body2" color="textSecondary">
                    {questions.length}/10 Questions
                </Typography>
            </Box>
            <List>
                {questions.map((question, index) => (
                    <ListItem
                        key={index}
                        component={Paper}
                        elevation={2}
                        sx={{
                            mb: 2,
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                            borderRadius: '8px',
                        }}
                    >
                        {editingIndex === index ? (
                            <Box display="flex" alignItems="center" width="100%">
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={2}
                                    value={editingText}
                                    onChange={(e) => setEditingText(e.target.value)}
                                    variant="outlined"
                                />
                                <IconButton onClick={() => handleEditSubmit(index)} color="primary">
                                    <SaveIcon />
                                </IconButton>
                                <IconButton onClick={() => handleEditCancel(index)} color="secondary">
                                    <CancelIcon />
                                </IconButton>
                            </Box>
                        ) : (
                            <>
                                <ListItemText
                                    primary={question.text}
                                    sx={{ pr: 2 }}
                                />
                                <ListItemSecondaryAction>
                                    {question.editable && (
                                        <>
                                            <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(index)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={() => onDiscardQuestion(index)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </>
                                    )}
                                </ListItemSecondaryAction>
                            </>
                        )}
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default QuestionnaireBuilder;