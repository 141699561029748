import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import styles from './TopBanner.module.css';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Menu, MenuItem, IconButton, Typography} from '@mui/material';

const TopBanner = ({userName, toggleSidebar, activeView, setActiveView, onLogout}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
    }, [userName]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleClose();
        onLogout();
    };

    const handleSettings = () => {
        handleClose();
        setActiveView('settings');
        navigate('/dashboard/settings');
    };

    return (
        <div className={styles.topBannerContainer}>
            <div className={styles.topBanner}>
                <div className={styles.leftSection}>
                    <button className={styles.hamburgerMenu} onClick={toggleSidebar}>☰</button>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_only.png?alt=media&token=6f439213-010b-4545-9235-07b97991bfac"
                        alt="Psyntel Logo" className={styles.logo}
                    />
                    <nav className={styles.navSection}>
                        <ul className={styles.navList}>
                            <li className={styles.navItem}>
                                <button
                                    className={`${styles.navLink} ${activeView === 'notes' ? styles.navLinkActive : ''}`}
                                    onClick={() => setActiveView('notes')}
                                >
                                    Notes
                                </button>
                            </li>
                            <li className={styles.navItem}>
                                <button
                                    className={`${styles.navLink} ${activeView === 'questionnaires' ? styles.navLinkActive : ''}`}
                                    onClick={() => setActiveView('questionnaires')}
                                >
                                    Questionnaires
                                </button>
                            </li>
                            <li className={styles.navItem}>
                                <button
                                    className={`${styles.navLink} ${activeView === 'insights' ? styles.navLinkActive : ''}`}
                                    onClick={() => setActiveView('insights')}
                                >
                                    Insights
                                    <AutoAwesomeOutlinedIcon className={styles.navLinkIcon}/>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className={styles.centerLogo}>
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/psyntel.appspot.com/o/psyntel_logo-removebg-preview.png?alt=media&token=c371a13e-f2b7-4887-b168-4470dcb7ef8a"
                        alt="Psyntel Centered Logo"
                        className={styles.centeredLogo}
                    />
                </div>
                <div className={styles.rightSection}>
                    <Typography variant="body1" className={styles.userName}>
                        {userName}
                    </Typography>
                    <IconButton onClick={handleClick} color="inherit" className={styles.userMenuButton}>
                        <AccountCircleIcon/>
                        <ExpandMoreIcon/>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleSettings}>Settings</MenuItem>
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    );
};

export default TopBanner;