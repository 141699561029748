import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { db } from '../config/firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, Box, Typography, Checkbox, ListItemText, Card, CardContent } from '@mui/material';

const PROFESSIONS = [
    'Psychiatrist',
    'Psychologist',
    'Licensed Clinical Social Worker (LCSW)',
    'Licensed Professional Counselor (LPC)',
    'Marriage and Family Therapist (MFT)',
    'Mental Health Nurse Practitioner',
    'Other'
];

const THERAPY_STYLES = [
    'Cognitive Behavioral Therapy (CBT)',
    'Psychodynamic Therapy',
    'Humanistic Therapy',
    'Dialectical Behavior Therapy (DBT)',
    'Eye Movement Desensitization and Reprocessing (EMDR)',
    'Mindfulness-Based Therapies',
    'Solution-Focused Brief Therapy (SFBT)',
    'Other'
];

const SettingsMenu = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    profession: '',
    therapyStyles: []
  });
  const [passwords, setPasswords] = useState({
    current: '',
    new: '',
    repeat: ''
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        setLoading(true);
        const userDoc = await getDoc(doc(db, 'Psyntel', user.uid, 'user_info', 'info'));
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData({
            firstName: data.firstName || '',
            lastName: data.lastName || '',
            email: data.email || '',
            profession: data.profession || '',
            therapyStyles: Array.isArray(data.therapyStyles) ? data.therapyStyles : []
          });
        }
        setLoading(false);
      }
    };
    fetchUserData();
  }, [user]);

  const handleInputChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleTherapyStylesChange = (event) => {
    setUserData({ ...userData, therapyStyles: event.target.value });
  };

  const handleSaveNameEmail = async () => {
    try {
      await updateDoc(doc(db, 'Psyntel', user.uid, 'user_info', 'info'), {
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email
      });
      alert('Name and email updated successfully');
    } catch (error) {
      console.error('Error updating name and email:', error);
      alert('Failed to update name and email');
    }
  };

  const handleSaveDetails = async () => {
    try {
      await updateDoc(doc(db, 'Psyntel', user.uid, 'user_info', 'info'), {
        profession: userData.profession,
        therapyStyles: userData.therapyStyles
      });
      alert('Details updated successfully');
    } catch (error) {
      console.error('Error updating details:', error);
      alert('Failed to update details');
    }
  };

  const handleChangePassword = async () => {
    if (passwords.new !== passwords.repeat) {
      alert("New passwords don't match");
      return;
    }
    try {
      const credential = EmailAuthProvider.credential(user.email, passwords.current);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, passwords.new);
      alert('Password changed successfully');
      setPasswords({ current: '', new: '', repeat: '' });
    } catch (error) {
      console.error('Error changing password:', error);
      if (error.code === 'auth/wrong-password') {
        alert('Current password is incorrect');
      } else if (error.code === 'auth/requires-recent-login') {
        alert('For security reasons, please log out and log back in before changing your password');
      } else {
        alert('Failed to change password. Please try again later.');
      }
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Settings
      </Typography>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Name & Email</Typography>
          <TextField
            fullWidth
            margin="normal"
            label="First Name"
            name="firstName"
            value={userData.firstName}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Last Name"
            name="lastName"
            value={userData.lastName}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            value={userData.email}
            onChange={handleInputChange}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSaveNameEmail}
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </CardContent>
      </Card>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h5" gutterBottom>Details</Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Profession</InputLabel>
            <Select
              value={userData.profession}
              label="Profession"
              name="profession"
              onChange={handleInputChange}
            >
              {PROFESSIONS.map((profession) => (
                <MenuItem key={profession} value={profession}>{profession}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Therapy Styles</InputLabel>
            <Select
              multiple
              value={userData.therapyStyles}
              onChange={handleTherapyStylesChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {THERAPY_STYLES.map((style) => (
                <MenuItem key={style} value={style}>
                  <Checkbox checked={userData.therapyStyles.indexOf(style) > -1} />
                  <ListItemText primary={style} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSaveDetails}
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>Change Password</Typography>
          <TextField
            fullWidth
            margin="normal"
            type="password"
            label="Current Password"
            value={passwords.current}
            onChange={(e) => setPasswords({...passwords, current: e.target.value})}
          />
          <TextField
            fullWidth
            margin="normal"
            type="password"
            label="New Password"
            value={passwords.new}
            onChange={(e) => setPasswords({...passwords, new: e.target.value})}
          />
          <TextField
            fullWidth
            margin="normal"
            type="password"
            label="Repeat New Password"
            value={passwords.repeat}
            onChange={(e) => setPasswords({...passwords, repeat: e.target.value})}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleChangePassword}
            sx={{ mt: 2 }}
          >
            Change Password
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SettingsMenu;
